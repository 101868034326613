import * as React from "react";
import Layout from "../../components/Layout";

// eslint-disable-next-line
export default () => (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <iframe src="https://eaitalento.zohorecruit.eu/forms/a6b4193f9db205d6d46d238b172ed2b57173132ad3ae0de8af5e6cb9cee487a6" width="100%" height="900px" ></iframe>
            </div>
          </div>
        </section>
      </Layout>
      );
  
